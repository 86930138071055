<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <span
        class="font-weight-light subtitle-1"
      >
        <v-btn
          elevation="1"
          color="blue darken-1"
          dark
          @click="goBack"
        >
          <v-icon
            color="white"
          >
            mdi-keyboard-backspace
          </v-icon>
          Back
        </v-btn>
    </span>  
    <template v-if="showSavedNotice">
      <v-row dense>
        <v-col
          cols="12"
          class="py-5"
        >
          <v-alert
            dense
            border="left"
            type="info"
            color="green"
          >
            Your report has been saved.
          </v-alert>
        </v-col>
      </v-row>
    </template>    
    <v-row dense>
      <v-col
        cols="12"
      >
        <base-material-card
          icon="mdi-alarm-multiple"
          color="secondary"
          style="background-color: #FAFAFA"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              Edit Mailer
            </div>
          </template>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-container class="py-5">
              <v-row dense>
                <v-col>
                  <v-text-field
                    outlined
                    ref="title"
                    v-model="title"
                    label="Title"
                    required
                    :rules="[v => !!v || 'Title is required']"
                    tabindex="1"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                  :items="allFrequencies"
                  label="Frequency"
                  outlined
                  item-text="frequency"
                  item-value="frequency"
                  v-model="frequency"
                  tabindex="2"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-combobox
                    outlined
                    v-model="headers"
                    :items="allHeaders"
                    label="Select the columns you want to appear"
                    multiple
                    tabindex="3"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="ftpServer"
                    label="FTP Server (optional)"
                    tabindex="4"
                  />
                </v-col>
              </v-row>    
              <v-row dense>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="ftpLogin"
                    label="FTP Login"
                    tabindex="5"
                  />
                </v-col>
              </v-row>   
              <v-row dense>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="ftpPassword"
                    label="FTP Password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    tabindex="6"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="ftpDirectory"
                    label="FTP Directory"
                    tabindex="7"
                  />
                </v-col>
              </v-row>    
              <v-row dense>
                <v-col>
                  <v-text-field
                    outlined
                    readonly
                    v-model="reportQuery"
                    label="Report Query"
                    tabindex="8"
                  />
                </v-col>
              </v-row> 
              <v-row dense>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="contactPerson"
                    label="Contact Person"
                    tabindex="9"
                  />
                </v-col>
              </v-row>    
              <v-row dense>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="contactTitle"
                    label="Contact Title"
                    tabindex="10"
                  />
                </v-col>
              </v-row>                                                                        
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    class="mr-0"
                    :disabled="!valid"
                    @click="saveReport()"
                  >
                    Update Report
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noPermissions"
      persistent
      width="600"
    >
      <v-card
        color="warning"
        dark
      >
        <v-alert
          type="warning"
        >
          You do not have permissions to access this feature. Contact Dealer Insights support for further assistance.
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="goHome()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import DmsService from '@/services/DmsService.js'  
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'  
import { nullLiteral, throwStatement } from '@babel/types'

  export default {
    name: 'EditMailer',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, titleMixin, navigationMixin],
    data () {
      return {
        loadingMessage: 'Loading',
        showErrorDialog: false,
        reportId: null,
        loading: true,
        error: '',
        expanded: [],
        noPermissions: false,
        valid: true,
        title: null,
        frequency: null,
        headers: [],
        ftpServer: null,
        ftpLogin: null,
        ftpPassword: null,
        ftpDirectory: null,
        reportQuery: null,
        contactPerson: null,
        contactTitle: null,
        allFrequencies: ['Daily', 'Weekly', 'Bi-Monthly', 'Monthly', 'Yearly'],
        showPassword: false, 
        showSavedNotice: false,   
        allHeaders: [
          { text: 'Customer Number', value: 'customer_number'},
          { text: 'Customer Birth Date', value: 'birth_date'},
          { text: 'Deal Number', value: 'deal_number' },
          { text: 'Sold Date', value: 'sold_at' },
          { text: 'Name', value: 'full_name' },
          { text: 'First Name', value: 'first_name'},
          { text: 'Last Name', value: 'last_name'},
          { text: 'City', value: 'city'},
          { text: 'State', value: 'state'},
          { text: 'Zip', value: 'zip'},
          { text: 'Year', value: 'year'},
          { text: 'Make', value: 'make'},
          { text: 'Model', value: 'model' },
          { text: 'Trim', value: 'trim'},
          { text: 'VIN', value: 'vin'},
          { text: 'New/Used', value: 'used'},
          { text: 'Price', value: 'sales_price'},
          { text: 'Cost', value: 'cost'},
          { text: 'VIN', value: 'vin' },
          { text: 'Stock Number', value: 'stock_number' },
          { text: 'Mileage', value: 'mileage'},
          { text: 'Sales Person', value: 'sales_person'},
          { text: 'Address 1', value: 'address_1'},
          { text: 'Address 2', value: 'address_2' },
          { text: 'Phone', value: 'phone'},
          { text: 'Email', value: 'email'},
          { text: 'APR', value: 'apr' },
          { text: 'Term', value: 'bank_term'},
          { text: 'Monthly Payment', value: 'monthly_payment'},
          { text: 'Bank', value: 'bank_id' },
          { text: 'Down Payment', value: 'down_payment'},
          { text: 'Term Date', value: 'term_date' },
          { text: 'Payments Remaining', value: 'months_remaining' },
          { text: 'Months Since Sale', value: 'months_since_sale' },          
          { text: 'Term Complete %', value: 'term_complete_percent' },
          { text: 'Address Validated?', value: 'validated'},
          { text: 'Sale Type', value: 'sale_type' },
          { text: 'Deal Type', value: 'deal_type'},
          { text: 'RO #', value: 'ro_number' },
          { text: 'Closed Date', value: 'close_date' },
        ]        
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      ...mapGetters(['accountId', 'accountName', 'permissions']),
    },
    watch: {
      accountId () {
        this.loadReport()
      },
    },
    created () {
      this.reportId = (this.$route.params.report_id)
      this.loadReport()
      this.$nextTick(() => {
        this.$refs.title.$refs.input.focus()
      })      
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadReport: function () {
        this.loadingMessage = 'Loading Report'
        this.items = []
        this.loading = true
        DmsService.getScheduledReport(this.reportId)
          .then(response => {
            this.title = response.data.title
            this.frequency = response.data.frequency
            this.headers = response.data.headers
            this.ftpServer = response.data.ftp_server
            this.ftpLogin = response.data.ftp_login
            this.ftpPassword = response.data.ftp_password
            this.ftpDirectory = response.data.ftp_directory
            this.reportQuery = JSON.stringify(response.data.query)
            this.contactPerson = response.data.contact_person
            this.contactTitle = response.data.contact_title
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      saveReport: function () {
        this.loadingMessage = 'Updating Report'
        this.loading = true
        this.valid = false

        const data = {
          reportId: this.reportId,
          title: this.title,
          frequency: this.frequency,
          headers: this.headers.map(h => typeof(h.value) === 'undefined' ? h : encodeURIComponent(h.value) ),
          ftpServer: this.ftpServer,
          ftpLogin: this.ftpLogin,
          ftpPassword: this.ftpPassword,
          ftpDirectory: this.ftpDirectory,
          contactPerson: this.contactPerson,
          contactTitle: this.contactTitle
        }
        DmsService.updateScheduledReport(data)
          .then(response => {
            this.loading = false
            this.valid = true
            this.showSavedNotice = true
          })
          .catch(error => {
            if (error.response.status === 418) {
              this.loading = false
              this.valid = true
            } else {
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
              this.loading = false
              this.valid = true
            }
          })
      },
      goHome: function () {
        this.$router.push({ name: 'Home' })
      },     
    },
  }
</script>
